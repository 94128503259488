import React from "react";
import Layout from "../components/Layout";
import { Presentation, Style } from "../components/Ansprechpartner/styled";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Ansprechpartner({ data: { team }, location }) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Ansprechpartner"}
      description={"Wir sind für Sie da."}
    >
      <Style>
        <Presentation>
          <div className={"image-wrapper"}>
            <GatsbyImage
              image={getImage(team)}
              alt={"Bild von Udo und Ilona Dannenfelser"}
              layout={"constrained"}
            />
          </div>
          <div className={"people"}>
            <div className={" left"}>
              <span>Udo Dannenfelser</span>
              <ul>
                <li>Geschäftsführer</li>
                <li>Kundenberatung</li>
                <li>Bauleitung</li>
              </ul>
            </div>

            <div className={" right"}>
              <span>Ilona Dannenfelser</span>
              <ul>
                <li>Terminvereinbarung</li>
                <li>Verwaltung</li>
              </ul>
            </div>
          </div>
        </Presentation>
      </Style>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    team: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: CONSTRAINED
        )
      }
    }
  }
`;
