import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import { colors } from "../../constants/colors";

export const Style = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Presentation = styled.div`
  display: flex;
  flex-direction: column;

  .image {
    //width: 400px;
  }

  .people {
    display: flex;
    justify-content: space-between;
    //width: 500px;
  }

  .right {
    text-align: right;
  }

  span {
    margin-top: ${spacing.spaceSm};
    font-weight: 600;
    font-size: 1.3rem;
  }

  ul {
    padding: 0;
    margin: ${spacing.spaceXs} 0;

    list-style-type: none;

    li {
      font-weight: 500;
      color: ${colors.greys["500"]};
    }
  }

  @media (max-width: 480px) {
    .people {
      flex-direction: column;
    }
  }
`;
